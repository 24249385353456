import React from 'react'
import { Link } from 'react-router-dom'
import styles from 'src/component/beecode/bc-header-logo.module.scss'
import { BeecodeLogoSvg } from 'src/component/beecode/util/images'

export const BcHeaderLogo = (): React.ReactElement => {
  return (
    <div className={styles.bcHeader}>
      <Link to="/" className="logo">
        <div className="img-holder shadow-sm">
          <BeecodeLogoSvg width="50" height="50" />
        </div>
      </Link>
    </div>
  )
}
