import { PersonalStatusLogoSvg } from '../../component/beecode/util/images'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const PsHomeScreen = (): React.ReactElement => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Personal Status</h1>
          <PersonalStatusLogoSvg height={80} className="float-start m-4" />
          <p>Welcome to Personal Status, the ultimate mobile app for quickly setting your status message on chat apps.</p>
          <p>
            With Personal Status, you can easily and quickly set your status message on Slack, the popular chat and collaboration
            tool for teams. And our team is currently working on integrating support for Microsoft Teams.
          </p>
          <p>
            Personal Status allows you to set your status message with just a few taps, saving you time and hassle. Whether you're
            busy in a meeting, out of the office, or just don't want to be disturbed, Personal Status makes it easy to communicate
            your availability to your team.
          </p>
          <p>
            You can even schedule your status messages in advance, so you never have to worry about forgetting to update your
            status. Plus, you can create custom status messages and save them for easy access in the future.
          </p>
          <p>
            With Personal Status, you can stay connected and productive with your team, without the hassle of manually updating
            your status message. Try it out today and experience the convenience for yourself!
          </p>
        </Col>
      </Row>
    </Container>
  )
}
