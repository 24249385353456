import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PsPrivacyPolicy } from 'src/component/personal-status/ps-privacy-policy'

export const PsPrivacyPolicyScreen = (): React.ReactElement => {
  return (
    <Container>
      <Row>
        <Col>
          <PsPrivacyPolicy />
        </Col>
      </Row>
    </Container>
  )
}
