import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ChevronRight } from 'src/component/beecode/util/icons'

export const BcProjectMshScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <div className="bc-page-title text-center">
        <h1>
          <span className="text-uppercase">MSH - Micro Service Helper</span>
        </h1>
      </div>
      <Container fluid>
        <Row>
          <Col>
            <div className="bc-next-link">
              <ChevronRight />
              <a href="https://github.com/beecode-rs/msh" target="_blank" rel="noreferrer">
                github/beecode-rs/msh
              </a>
            </div>
            <h2>Idea</h2>
            <p>
              The idea behind MSH is a set of tools mimicking a framework in decoupling manner (Micro-Framework). It is written to
              be used in Typescript/Node.js projects (and can also be used in web projects)
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
