import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { BcSite } from 'src/screen/beecode/bc-site'
import { PsSite } from 'src/screen/personal-status/ps-site'

export const SiteRouter = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/projects/personal-status*" element={<PsSite />} />
        <Route path="/*" element={<BcSite />} />
        {/*PROTECTED*/}
        {/*<Route path="/" element={<ProtectedAuthOnlyRoute children={<HomeScreen />} />} />*/}

        {/*CATCH ALL*/}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}
