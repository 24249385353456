import { PsNavBar } from '../../component/personal-status/ps-nav-bar'
import React from 'react'
import { PsRouter } from 'src/screen/personal-status/ps-router'

export const PsSite = (): React.ReactElement => {
  return (
    <>
      <PsNavBar />
      <PsRouter />
    </>
  )
}
