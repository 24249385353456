import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PersonalStatusLogoSvg, PlaceholderSvg, TimeVisionLogoSvg } from 'src/component/beecode/util/images'

export const BcHomeScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <div className="bc-page-title text-center">
        <h1>
          <span className="text-uppercase">Beecode</span>
        </h1>
      </div>

      <div className="bc-page-body text-center">
        <p>
          I'm Miloš Bugarinović, a developer working and living in Belgrade, Serbia. I'm happy to share my knowledge and always
          ready to discuss new theories and practices in programming.
        </p>
        <p>
          I've gathered a small team of people behind the name Beecode so we can work on new existing projects, exchange ideas,
          and have fun. Each team member is a professional in their respective field, and we are always looking for new
          collaborators.
        </p>
      </div>

      <Container fluid>
        <h2>Projects</h2>
        <Row className="text-center">
          <Col sm={4}>
            <Link to="/projects/time-vision">
              <TimeVisionLogoSvg height={80} className="m-4" />
              <h3>
                Time Vision
                <br />
                (timestamp convertor)
              </h3>
            </Link>
            <p className="text-center">Chrome extension</p>
          </Col>

          <Col sm={4}>
            <Link to="/projects/personal-status">
              <PersonalStatusLogoSvg height={80} className="m-4" />
              <h3>Personal Status</h3>
            </Link>
            <p className="text-center">Mobile app (work in progress)</p>
          </Col>

          <Col sm={4}>
            <Link to="/projects/msh">
              <PlaceholderSvg height={80} className="m-4" />
              <h3>MSH - Micro Service Helper</h3>
            </Link>
            <p className="text-center">OpenSource tools for microservices</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
