import React from 'react'
import styles from 'src/component/beecode/bc-footer.module.scss'

export const BcFooter = (): React.ReactElement => {
  return (
    <footer>
      <div className={styles.copyright}>© 2023 Beecode, all rights reserved.</div>
    </footer>
  )
}
