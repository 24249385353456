import styles from './bc-side-menu.module.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { BcHeaderLogo } from 'src/component/beecode/bc-header-logo'

export const BcSideMenu = (): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  const isHamburgerVisible = useMediaQuery({ query: '(max-width: 768px)' })

  const renderHamburger = useMemo((): React.ReactElement => {
    return (
      <div className={`${styles.menuToggleBtn} material-design-hamburger`}>
        <button
          className="material-design-hamburger__icon"
          onClick={() => {
            setIsOpen((prevState) => !prevState)
          }}
        >
          <span
            className={`material-design-hamburger__layer ${
              isOpen ? 'material-design-hamburger__icon--to-arrow' : 'material-design-hamburger__icon--from-arrow'
            }`}
          ></span>
        </button>
      </div>
    )
  }, [isOpen])

  useEffect(() => {
    if (isHamburgerVisible) return
    setIsOpen(false)
  }, [isHamburgerVisible])
  return (
    <>
      <div className={`${styles.sideMenu} ${isOpen ? 'open' : ''}`}>
        <div className="bc-title">
          <Link to="/" className="text-center">
            <h1 className="name">Beecode</h1>
          </Link>
        </div>

        <ul>
          <li>
            <Link to="/projects">
              <i className="el el-icon-lightbulb"></i>
              <span>Projects</span>
            </Link>
          </li>
          <li>
            <Link to="/blog">
              <i className="el el-icon-book-open"></i>
              <span>Blog</span>
            </Link>
          </li>
          {/*<li>*/}
          {/*  <a href="#/">*/}
          {/*    <i className="el el-icon-upload"></i>*/}
          {/*    <span>Application</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#/">*/}
          {/*    <i className="el el-icon-briefcase"></i>*/}
          {/*    <span>Enterprise</span>*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href="#/">*/}
          {/*    <i className="el el-icon-strategy"></i>*/}
          {/*    <span>Copywriting</span>*/}
          {/*  </a>*/}
          {/*</li>*/}

          {/*<li className={styles.separator}></li>*/}

          {/*<li>*/}
          {/*  <a href="#/">*/}
          {/*    <i className="el el-icon-trophy"></i>*/}
          {/*    <span>Portfolio</span>*/}
          {/*  </a>*/}
          {/*</li>*/}

          {/*<li>*/}
          {/*  <a href="#/">*/}
          {/*    <i className="el el-icon-wine"></i>*/}
          {/*    <span>Contact</span>*/}
          {/*  </a>*/}
          {/*</li>*/}

          <li>
            <Link to="/about">
              <i className="el el-icon-hotairballoon"></i>
              <span>About</span>
            </Link>
          </li>
        </ul>
      </div>

      <BcHeaderLogo />
      {renderHamburger}
    </>
  )
}
