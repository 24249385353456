import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const BcBlogScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <Container fluid>
        <Row>
          <Col>
            <h1>Blog</h1>
            <h2>Under Construction</h2>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
