import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PsHomeScreen } from 'src/screen/personal-status/ps-home-screen'
import { PsPrivacyPolicyScreen } from 'src/screen/personal-status/ps-privacy-policy-screen'
import { PsTermsAndConditionsScreen } from 'src/screen/personal-status/ps-terms-and-conditions-screen'

export const PsRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<PsHomeScreen />} />
      <Route path="/privacy" element={<PsPrivacyPolicyScreen />} />
      <Route path="/terms-and-conditions" element={<PsTermsAndConditionsScreen />} />
      {/*PROTECTED*/}
      {/*<Route path="/" element={<ProtectedAuthOnlyRoute children={<HomeScreen />} />} />*/}

      {/*CATCH ALL*/}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
