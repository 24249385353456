import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BcAboutScreen } from 'src/screen/beecode/bc-about-screen'
import { BcBlogScreen } from 'src/screen/beecode/bc-blog-screen'
import { BcHomeScreen } from 'src/screen/beecode/bc-home-screen'
import { BcProjectMshScreen } from 'src/screen/beecode/projects/bc-project-msh-screen'
import { BcProjectTimeVisionScreen } from 'src/screen/beecode/projects/bc-project-time-vision-screen'
import { BcProjectsScreen } from 'src/screen/beecode/projects/bc-projects-screen'
// import { BcUnderConstructionScreen } from 'src/screen/beecode/bc-under-construction-screen'

export const BcRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<BcHomeScreen />} />
      <Route path="/about" element={<BcAboutScreen />} />
      <Route path="/projects" element={<BcProjectsScreen />} />
      <Route path="/projects/time-vision" element={<BcProjectTimeVisionScreen />} />
      <Route path="/projects/msh" element={<BcProjectMshScreen />} />
      <Route path="/blog" element={<BcBlogScreen />} />
      {/*<Route path="/" element={<BcUnderConstructionScreen />} />*/}
      {/*PROTECTED*/}
      {/*<Route path="/" element={<ProtectedAuthOnlyRoute children={<HomeScreen />} />} />*/}

      {/*CATCH ALL*/}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
