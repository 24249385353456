import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PsTermsAndConditions } from 'src/component/personal-status/ps-terms-and-conditions'

export const PsTermsAndConditionsScreen = (): React.ReactElement => {
  return (
    <Container>
      <Row>
        <Col>
          <PsTermsAndConditions />
        </Col>
      </Row>
    </Container>
  )
}
