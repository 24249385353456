import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Gravatar from 'react-gravatar'

export const BcAboutScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <Container fluid>
        <Row>
          <Col>
            <h1>About</h1>
            <h2>Miloš Bugarinović</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Gravatar className="float-end m-4" size={200} email="milos.bugarinovic@gmail.com" />
            <p>
              I grew up playing first-person shooter video games like DOOM and Quake, and listening to the music of Sepultura and
              Pantera. I am also a guitar player, and enjoy playing both metal and classical music. Throughout my career, I have
              worked on software for the tracking and logistics industries.
            </p>

            <p>
              In my free time, I am always experimenting with new technology. I have a small workshop filled with various tools
              and enjoy working with Raspberry Pi and IoT projects. I am also a big fan of solving logical puzzles and working in
              a team. I believe that "a team that works together, achieves together."
            </p>

            <p>
              In addition to my passion for technology, I also have a green thumb and enjoy growing herbs. Overall, I am driven by
              a desire to constantly learn and improve, and I am always looking for new challenges to tackle.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
