import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PersonalStatusLogoSvg, PlaceholderSvg, TimeVisionLogoSvg } from 'src/component/beecode/util/images'

export const BcProjectsScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <Container fluid>
        <Row>
          <Col>
            <h1>Projects</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/projects/time-vision">
              <TimeVisionLogoSvg width={40} className="float-start m-4" />
              <h2 className="mt-4">Time Vision</h2>
            </Link>
            <p>Chrome extension</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/projects/personal-status">
              <PersonalStatusLogoSvg width={40} className="float-start m-4" />
              <h2 className="mt-4">Personal Status</h2>
            </Link>
            <p>Mobile app (work in progress)</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/projects/msh">
              <PlaceholderSvg width={40} className="float-start m-4" />
              <h2 className="mt-4">MSH - Micro Service Helper</h2>
            </Link>
            <p>OpenSource tools for microservices</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
