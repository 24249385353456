import React from 'react'
import { BcFooter } from 'src/component/beecode/bc-footer'
import { BcSideMenu } from 'src/component/beecode/bc-side-menu'
import { BcRouter } from 'src/screen/beecode/bc-router'

export const BcSite = (): React.ReactElement => {
  return (
    <div className="bc-site">
      <BcSideMenu />
      <BcRouter />
      <BcFooter />
    </div>
  )
}
