import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ChevronRight } from 'src/component/beecode/util/icons'

export const BcProjectTimeVisionScreen = (): React.ReactElement => {
  return (
    <div className="bc-container-page ">
      <div className="bc-page-title text-center">
        <h1>
          <span className="text-uppercase">Time Vision</span>
        </h1>
      </div>
      <Container fluid>
        <Row>
          <Col>
            <div className="bc-next-link">
              <ChevronRight />
              <a
                href="https://chrome.google.com/webstore/detail/jinaajoemlelomgofkifegljinbghhdk"
                target="_blank"
                rel="noreferrer"
              >
                Chrome Extension
              </a>
            </div>
            <p>Convert unix timestamp to readable format.</p>

            <p>
              Time-vision is a dev tool created to help developers in reading and converting unix timestamps in frontend apps.
            </p>

            <p>
              The idea came from the decision of our dev team to use timestamp instead of the javascript date object in our
              business models (so we would not need to convert string to date).
            </p>

            <p>
              Usage: Select a number in any web page that represents the unix timestamp and extension is going to try and convert
              it to a readable date and display it in a popup next to the selected number. In the options section we have an
              ability to set a format of the readable date and time zone, also we have an option to display date in multiple
              formats and timezones.
            </p>

            <p>
              Advanced features: Extension is trying to detect the value of the number selected, distinguishing between seconds,
              milliseconds, nanoseconds…
            </p>

            <p>
              Planned features: Switch between auto detecting number value and desired format Toggle on/off in extension popup
              Update UI/UX
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
